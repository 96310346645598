import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import './EditItem.css';
import FileUploader from './FileUploader';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// TODO:  maybe do the above higher up in the app?

/*
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};*/

class EditItem extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        item: PropTypes.object, // may be null if we are not open

        // Redux functions
        editItemCompleted: PropTypes.func.isRequired,

        // Firebase functions
        updateItem: PropTypes.func.isRequired,
        deleteItemImage: PropTypes.func.isRequired,
        deleteItemHistory: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            itemName: '', // prevents null property error when we render
            safeway: false,
            itemImageUrl: null,
            uploading: false, // when uploading we disable buttons
            formDirty: false,
        };

        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';

        // Re-initialize any state here!
        const { item } = this.props;
        this.setState({
            itemName: item.name,
            safeway: item.safeway ? true : false,
            itemImageUrl: item.imageUrl,
            uploading: false,
            formDirty: false,
        });
    }

    closeModal() {
        const { editItemCompleted } = this.props;
        const { formDirty } = this.state;

        if (formDirty) {
            // TODO: make save button work like expected
            this.saveFormData();
        }

        editItemCompleted();
    }

    handleDisableButtons = (location) => {
        const { uploading } = this.state;

        if (!uploading) {
            console.log(`--- DISABLING BUTTONS --- [ ${location} ]`);
            this.setState({ uploading: true });
        }
    };
    handleEnableButtons = (location) => {
        const { uploading } = this.state;
        if (uploading) {
            console.log(`--- ENABLING BUTTONS --- [ ${location} ]`);
            this.setState({ uploading: false });
        }
    };

    setImage = (imageId, imageUrl) => {
        const { item, updateItem } = this.props;
        //console.log('setImage()', id, url);

        console.log('--- ENABLING BUTTONS --- (setImage)');
        this.setState({ uploading: false, itemImageUrl: imageUrl });

        // Update the item in firebase
        const newItem = Object.assign({}, item, {
            imageId,
            imageUrl
        });
        updateItem(newItem);
    };

    clearImage = () => {
        const { item, deleteItemImage } = this.props;
        const { itemImageUrl } = this.state;

        //console.log('clearImage', item, itemImageUrl);

        // The start and end are getting called not only on adding an image, but multiple times
        // when you delete an image.  So we do a bit of extra state management just to be sure
        // we don't call the server multiple times.
        if (itemImageUrl) {
            this.setState({ itemImageUrl: null, uploading: false });
            deleteItemImage(item);
        }

        // we still want to call this even if we don't delete an image, so that the button state is correct
        this.setState({ itemImageUrl: null, uploading: false });
    };

    handleChange = (event) => {
        this.setState({ itemName: event.target.value, formDirty: true });
    };
    handleBlur = (x) => {
        // TODO: make save button work like expected
        this.saveFormData();
    };

    handleSafewayChange = (event) => {
        this.setState({ safeway: event.target.checked, formDirty: true });
    };

    // TODO: make save button work like expected
    saveFormData = () => {
        const { item, updateItem } = this.props;
        const { itemName, safeway } = this.state;

        // props.item is coming from redux, so don't modify the object
        // create a new updated item, and call this.props.updateItem()
        const newItem = Object.assign({}, item, {
            name: itemName,
            safeway,
        });
        updateItem(newItem);

        // and now clear the dirty flag ...
    };

    handleDeleteItemHistory = () => {
        const { item, deleteItemHistory } = this.props;

        deleteItemHistory(item);
        this.closeModal();
    };

    getImageRender = () => {
        const { item } = this.props;

        if (!item) return null; // the dialog is always present, we just hide it and don't give it an item
        return (
            <FileUploader
                itemId={item ? item.id : null}
                itemUrl={item ? item.imageUrl : null}
                onRequestSave={this.setImage}
                onRequestClear={this.clearImage}
                disableButtons={this.handleDisableButtons}
                enableButtons={this.handleEnableButtons}
            />
        );
    };

    render() {
        const { item, isOpen } = this.props;
        const { itemName, safeway, uploading, formDirty } = this.state;

        const closeButtonLabel = formDirty ? 'Save' : 'Close';

        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    //style={customStyles}
                    className="edititemModal"
                    contentLabel="Edit Item"
                >
                    <div className="edititem_wrapper">
                        <div className="edititem_btntop">
                            <div className="edititem_deleterow">
                                <button
                                    className="edititem_deletebutton"
                                    onClick={this.handleDeleteItemHistory}
                                    disabled={uploading}
                                >
                                    Delete History
                                </button>
                                <button className="edititem_closebutton" onClick={this.closeModal} disabled={uploading}>
                                    {closeButtonLabel}
                                </button>
                            </div>
                        </div>
                        <div className="edititem_header">
                            <span className="edititem_headertxt">Edit Item</span>
                        </div>
                        <div className="edititem_inputrow">
                            <input
                                className="edititem_input"
                                type="text"
                                value={itemName}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                            />
                        </div>

                        <div className="edititem_categoryrow">
                            Category: {item ? item.category : ''}
                            <div className="edititem_safeway">
                                <input
                                    className="edititem_checkbox"
                                    type="checkbox"
                                    checked={safeway}
                                    onChange={this.handleSafewayChange}
                                />
                                Safeway
                            </div>
                        </div>
                        <br />

                        <div className="edititem_imagewrapper">{this.getImageRender()}</div>
                        <div className="edititem_btnbottom">
                            <div className="edititem_deleterow">
                                <button
                                    className="edititem_deletebutton"
                                    onClick={this.handleDeleteItemHistory}
                                    disabled={uploading}
                                >
                                    Delete History
                                </button>
                                <button className="edititem_closebutton" onClick={this.closeModal} disabled={uploading}>
                                    {closeButtonLabel}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default EditItem;
