import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { editItemCompleted } from './redux/actions';
import { updateItem, deleteItemImage, deleteItemHistory } from './redux/firebasehandlers';
import { findCategory } from './CategorySorter';
import EditItem from './EditItem';

const mapStateToProps = state => {
    let item = null;
    if (state.edititem.itemId) {
        // we need to add the key into the object
        item = Object.assign(
            {
                id: state.edititem.itemId,
            },
            state.firestore.data.currentItemList[state.edititem.itemId]
        );
        item.category = findCategory(item.name);
    }
    //console.log('EditItemContainer.mapStateToProps', state.edititem.itemId, item);

    return {
        shoppinglistId: state.firebase.profile.shoppinglist,
        isOpen: state.edititem.editing,
        item: item
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //editItemStart: item => dispatch(editItemStart(item)),
        editItemCompleted: () => dispatch(editItemCompleted())
    };
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firebaseConnect(),
    firestoreConnect(),
    withHandlers({
        updateItem,
        deleteItemImage,
        deleteItemHistory
    })
)(EditItem);
