import { connect } from 'react-redux';
//import { compose } from 'redux';
import { compose, withHandlers } from 'recompose';
import { firebaseConnect, firestoreConnect, isLoaded } from 'react-redux-firebase';
import { editItemStart } from './redux/actions';
import App from './App';
import { findCategory } from './CategorySorter';
import {
    addNewItem,
    addItemFromHistory,
    deleteItem,
    addNewItemToMealPlan,
    addHistoryItemToMealPlan,
} from './redux/firebasehandlers';

const mapStateToProps = (state) => {
    // I used to use the data in state.firestore.ordered, which really just stores the same data as an array,
    // rather than as an object.  I'm not sorting the data as it comes back from the db (we sort it just below here),
    // so I'm going to switch to use state.firestore.data to avoid the bug I entered:
    // https://github.com/prescottprue/redux-firestore/issues/243
    // If that issue gets fixed, I could add sorting on the db side and sort by the category field, which is
    // all I'm doing here.
    // NOTE:  you have to add the 'id' field to the firestore.data objects
    let currentList = [];
    let historyList = [];

    if (state && state.firestore && state.firestore.data && state.firestore.data.currentItemList) {
        if (state.firestore.data.currentItemList) {
            Object.keys(state.firestore.data.currentItemList).forEach(function (key) {
                const item = state.firestore.data.currentItemList[key];
                if (item) {
                    // if a record is deleted, the key remains with a null object
                    currentList.push(
                        Object.assign(
                            {
                                id: key,
                            },
                            item
                        )
                    );
                }
            });
        }
    }
    if (state.firestore.data.historyItemList) {
        Object.keys(state.firestore.data.historyItemList).forEach(function (key) {
            const item = state.firestore.data.historyItemList[key];
            if (item) {
                historyList.push(
                    Object.assign(
                        {
                            id: key,
                        },
                        item
                    )
                );
            }
        });
    }

    // Let's ignore the stored categories (why were we doing that??) and do them on the fly here (so that they update as a change and improve them!)
    const items = currentList.map((item) => {
        return Object.assign({}, item, { category: findCategory(item.name) });
    });

    //console.error('Category Results: ', JSON.stringify(items, null, 2));
    //console.error('mapStateToProps: ', JSON.stringify(historyList, null, 2));
    const sortedItems = items
        ? items.sort((a, b) => {
              let first = a.category ? a.category.toUpperCase() : 'ZZZZZZZ'; // use Z's to sort them to the bottom
              let second = b.category ? b.category.toUpperCase() : 'ZZZZZZZ';
              if (a.safeway) {
                  first = 'ZZZZZZZ_ZZZ';
              }
              if (b.safeway) {
                  second = 'ZZZZZZZ_ZZZ';
              }
              if (first < second) {
                  return -1;
              }
              if (first > second) {
                  return 1;
              }
              // By the process of deduction, the categories must be the same, so now return a comparison based on the item names themselves.
              return a.name.localeCompare(b.name);
              //return 0;
          })
        : null;

    console.warn(
        'SORTED ITEMS: ',
        sortedItems.map((element) => {
            return Object.assign({}, element.safeway ? { safeway: element.safeway } : null, {
                category: element.category,
                name: element.name,
            });
        })
    );

    // don't pull them from firestore.data, because they don't have ids ...
    //const itemsById = {};
    //sortedItems.forEach((item) => (itemsById[item.id] = item));

    return {
        isListLoaded: isLoaded(state.firestore.data.currentItemList),
        shoppinglistId: state.firebase.profile.shoppinglist,
        //itemsById,
        items: sortedItems,
        historyitems: historyList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editItemStart: (item) => dispatch(editItemStart(item)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect((props) => {
        if (!props.shoppinglistId) return [];
        //console.error('firestoreConnect.props', JSON.stringify(props, null, 2));
        return [
            {
                collection: 'shoppinglists',
                doc: props.shoppinglistId,
                subcollections: [{ collection: 'items' }],
                storeAs: 'currentItemList',
            },
            {
                collection: 'shoppinglists',
                doc: props.shoppinglistId, //'3uMATa0TZN46CzvdITI6',
                subcollections: [{ collection: 'historyitems' }],
                //where: [['uid', '==', props.uid]]
                storeAs: 'historyItemList',
            },
            {
                collection: 'shoppinglists',
                doc: props.shoppinglistId,
                subcollections: [{ collection: 'mealplans' }],
                storeAs: 'mealPlans',
            },

            {
                collection: 'shoppinglists',
                doc: props.shoppinglistId,
                subcollections: [{ collection: 'meals' }],
                storeAs: 'meals',
            },
            {
                collection: 'shoppinglists',
                doc: props.shoppinglistId,
                subcollections: [{ collection: 'historymeals' }],
                storeAs: 'historymeals',
            },

            {
                collection: 'shoppinglists',
                doc: props.shoppinglistId,
                subcollections: [{ collection: 'info', doc: 'meallist' }],
                storeAs: 'shoppinglistinfo',
            },
        ];
    }),
    withHandlers({
        addNewItem: addNewItem,
        addItemFromHistory: addItemFromHistory,
        deleteItem: deleteItem,

        addNewItemToMealPlan: addNewItemToMealPlan,
        addHistoryItemToMealPlan: addHistoryItemToMealPlan,
    })
)(App);
