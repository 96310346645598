const initialState = { selectedList: null };

export const ACTION_SELECT_LIST = "ACTION_SELECT_LIST";

export default function reducer(state = initialState, action) {
    if (action.type === ACTION_SELECT_LIST) {
        return { ...state, selectedList: action.list };
    }
    return state;
}
