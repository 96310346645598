import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NewItem from '../components/NewItem';
import './MealPlanningMode.css';
import EditMealPlanContainer from './EditMealPlanContainer';

class ListItem extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        deleteItem: PropTypes.func.isRequired,
    };

    deleteItem = () => {
        const { item, deleteItem } = this.props;
        deleteItem(item);
    };

    render() {
        const { item } = this.props;
        return (
            <div className="mpm_listitem">
                <div className="mpm_listitem_x" onClick={this.deleteItem}>
                    x
                </div>
                <div className="mpm_listitem_name">{item.name}</div>
            </div>
        );
    }
}

class OneDayMealPlan extends Component {
    static propTypes = {
        mealPlan: PropTypes.object.isRequired,
        myMealPlan: PropTypes.object,
        historyitems: PropTypes.array.isRequired,

        // Operations
        addItem: PropTypes.func.isRequired,

        // Firebase handlers
        deleteItem: PropTypes.func.isRequired,
    };

    deleteItem = (item) => {
        const { deleteItem } = this.props;
        deleteItem(item);
    };

    handleClickAddItem = () => {
        const { myMealPlan, addItem } = this.props;
        console.error('handleClickAddItem', myMealPlan);
        addItem(myMealPlan);
    };

    selectMealPlan = () => {
        const { myMealPlan, editMealPlanStart } = this.props;
        editMealPlanStart(myMealPlan.id);
    };

    render() {
        const { mealPlan, myMealPlan } = this.props;
        //console.log('myMealPlan', myMealPlan);

        return (
            <div className="mp_week">
                <div className="mp_week_header">
                    <div className="mp_day">{myMealPlan.title}</div>
                    {myMealPlan.mealPlan ? (
                        <div className="mp_week_additembtn" onClick={this.handleClickAddItem}>
                            +
                        </div>
                    ) : null}

                    <div>
                        {myMealPlan.mealPlan ? myMealPlan.mealPlan.name : ''}{' '}
                        <button onClick={this.selectMealPlan}>Edit</button>
                    </div>
                </div>
                {myMealPlan.mealPlanItems.map((item) => {
                    return <ListItem key={item.id} item={item} mealPlan={mealPlan} deleteItem={this.deleteItem} />;
                })}
            </div>
        );
    }
}

class ModalDrawer extends Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        closeDrawer: PropTypes.func.isRequired,

        // data
        mealPlan: PropTypes.object,
        historyitems: PropTypes.array.isRequired,

        // Firebase handlers
        addNewItemToMealPlan: PropTypes.func.isRequired,
        addHistoryItemToMealPlan: PropTypes.func.isRequired,
    };

    addNewItem = (itemName) => {
        const { addNewItemToMealPlan, myMealPlan, closeDrawer } = this.props;

        addNewItemToMealPlan(
            {
                name: itemName,
            },
            myMealPlan
        );

        closeDrawer();
    };
    addItemFromHistory = (historyItem) => {
        const { addHistoryItemToMealPlan, myMealPlan, closeDrawer } = this.props;

        console.error('addItemFromHistory', historyItem, myMealPlan);

        addHistoryItemToMealPlan(historyItem, myMealPlan);

        closeDrawer();
    };

    handleClose = () => {
        const { closeDrawer } = this.props;
        closeDrawer();
    };

    getContent = () => {
        const { open, historyitems } = this.props;
        if (!open) {
            return null;
        }

        return (
            <div key="something" className="drawer">
                <div className="drawer_close" onClick={this.handleClose}>
                    x
                </div>
                <div className="drawer_newitem_wrapper">
                    <NewItem
                        handleAddItem={this.addNewItem}
                        handleAddItemFromHistory={this.addItemFromHistory}
                        history={historyitems}
                        autoFocus={true}
                    />
                </div>
            </div>
        );
    };

    render() {
        return this.getContent();
    }
}

class MealPlanningMode extends Component {
    static propTypes = {
        // Lists of data
        myMealPlans: PropTypes.array.isRequired,
        historyitems: PropTypes.array.isRequired,

        // Firebase handlers
        addNewItemToMealPlan: PropTypes.func.isRequired,
        addHistoryItemToMealPlan: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            addingItem: false,
            addingMyMealPlan: null,
        };
    }

    startAddingItem = (myMealPlan) => {
        console.log('Ok, going to edit!', myMealPlan);
        this.setState({
            addingItem: true,
            addingMyMealPlan: myMealPlan,
        });
    };
    doneAddingItem = () => {
        console.log('Ok, done editing!');
        this.setState({
            addingItem: false,
            addingMealPlan: null,
            addingMyMealPlan: null,
        });
    };

    renderDays() {
        const {
            myMealPlans,
            historyitems,
            deleteItem,
            editMealPlanStart,
        } = this.props;

        console.log('MealPlans', myMealPlans);

        return myMealPlans.map((mealPlan, index) => {
            return (
                <OneDayMealPlan
                    key={index}
                    mealPlan={mealPlan}
                    myMealPlan={myMealPlans[index]}
                    historyitems={historyitems}
                    deleteItem={deleteItem}
                    addItem={this.startAddingItem}
                    editMealPlanStart={editMealPlanStart}
                />
            );
        });
    }

    render() {
        const { addingItem, addingMealPlan, addingMyMealPlan } = this.state;
        const { addNewItemToMealPlan, addHistoryItemToMealPlan, historyitems } = this.props;

        return (
            <div>
                <ModalDrawer
                    open={addingItem}
                    closeDrawer={this.doneAddingItem}
                    mealPlan={addingMealPlan}
                    myMealPlan={addingMyMealPlan}
                    historyitems={historyitems}
                    addNewItemToMealPlan={addNewItemToMealPlan}
                    addHistoryItemToMealPlan={addHistoryItemToMealPlan}
                />
                <EditMealPlanContainer />
                <div className="mpm_wrapper">{this.renderDays()}</div>
            </div>
        );
    }
}

export default MealPlanningMode;
