import React, { Component } from 'react';
import PropTypes from 'prop-types';

// need both of these lines to get firebase.storage() to work ...
import firebase from 'firebase/app';
import 'firebase/storage';

import FileUploader from 'react-firebase-file-uploader';

/*
 * If we have an image:
 * - Display Image
 * - Remove Image button
 *
 * If we don't have an image:
 * - Add Image button ...
 */

class FileUploadWrapper extends Component {
    static propTypes = {
        itemId: PropTypes.string.isRequired,
        itemUrl: PropTypes.string, // will be null if there is no image
        onRequestSave: PropTypes.func.isRequired,
        onRequestClear: PropTypes.func.isRequired,
        disableButtons: PropTypes.func.isRequired,
        enableButtons: PropTypes.func.isRequired,
    };

    render() {
        const { itemId, itemUrl, onRequestClear } = this.props;

        if (itemUrl) {
            return <HaveImage imageUrl={itemUrl} removeImage={onRequestClear} />;
        } else {
            return <MyFileUploader itemId={itemId} {...this.props} />;
        }
    }
}

class HaveImage extends Component {
    static propTypes = {
        imageUrl: PropTypes.string.isRequired,
        removeImage: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        }
    }

    render() {
        console.log('HaveImage.render()', this.state);
        const { loaded } = this.state;

        return (
            <div className="haveimage">
                <img alt="" src={this.props.imageUrl} onLoad={() => this.setState({loaded: true})} />
                { loaded ? <button className="close-icon" onClick={this.props.removeImage} />  : null }
            </div>
        );
    }
}
/*
                <button
                    onClick={this.props.removeImage}
                    style={{
                        backgroundColor: 'steelblue',
                        color: 'white',
                        padding: 10,
                        borderRadius: 4,
                        cursor: 'pointer',
                    }}
                >
                    Remove Image
                </button>
*/

class MyFileUploader extends Component {
    static propTypes = {
        itemId: PropTypes.string.isRequired,
        onRequestSave: PropTypes.func.isRequired,
        onRequestClear: PropTypes.func.isRequired,
        disableButtons: PropTypes.func.isRequired,
        enableButtons: PropTypes.func.isRequired,
    };

    state = {
        avatar: '',
        isUploading: false,
        progress: 0,
        avatarURL: '',
    };

    handleUploadStart = (file) => {
        console.log('handleUploadStart', file);

        const { disableButtons } = this.props;
        disableButtons();

        this.setState({ isUploading: true, progress: 0 });
    };
    handleProgress = (progress) => {
        console.log('handleProgress', progress);
        this.setState({ progress });
    };
    handleUploadError = (error) => {
        console.error(error);

        const { enableButtons } = this.props;
        enableButtons();

        this.setState({ isUploading: false });
    };
    handleUploadSuccess = (filename) => {
        console.log('handleUploadSuccess', filename);
        const { itemId, enableButtons, onRequestSave } = this.props;
        enableButtons();
        this.setState({ avatar: filename, progress: 100, isUploading: true /*false*/ });
        firebase
            .storage()
            .ref('images')
            .child(filename)
            .getDownloadURL()
            .then((url) => {
                console.log('url: ', url);
                this.setState({ avatarURL: url });
                onRequestSave(filename, url);
            });
    };

    render() {
        //console.log('MyFileUploader.render()', this.state);
        return (
            <form>
                <div className="mfu_wrapper">
                    {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                    {/*this.state.avatarURL && <img alt="" src={this.state.avatarURL} />*/}
                    <label
                        style={{
                            backgroundColor: 'steelblue',
                            color: 'white',
                            padding: 10,
                            borderRadius: 4,
                            cursor: 'pointer',
                        }}
                    >
                        Add Image
                        <FileUploader
                            hidden
                            accept="image/*"
                            maxHeight={300}
                            maxWidth={256}
                            name="avatar"
                            filename={(file) => {
                                console.log('filename called', file);
                                return this.props.itemId;
                            }
                            }
                            storageRef={firebase.storage().ref('images')}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                        />
                    </label>
                </div>
            </form>
        );
    }
}

export default FileUploadWrapper;
