import { findCategory } from '../CategorySorter';
import { publish } from '../util/pubsub';

/**
 * Item Fields:
 * {
 *  name: string,
 *  imageUrl: http url
 *  imageId: used for deleting the source image
 *  useCount: number of times an item is added to the item list from the history
 *  safeway: boolean, not present is "false"
 *  category: created at item loading time in the UI, not necessary to save, but not harmful either ...
 *  mealPlanId: ...
 * }
 *
 * any reason a historyItem can't have the same fields??
 *
 * HistoryItem Fields:
 *  - same as above ...
 *  - A history item should not have a meanPlanId, otherwise when it gets added to the main list,
 *    it'll show up in that meal plan.
 *
 */

// mealPlan may be null ...
const reallyAddNewItem = (props, item, newMealPlan) => {
    const historyItemRef = props.firestore
        .collection('shoppinglists')
        .doc(props.shoppinglistId)
        .collection('historyitems')
        .doc(); // will create a new document with an id (another way to call add())

    props.firestore
        .runTransaction((t) => {
            return t.get(historyItemRef).then((doc) => {
                t.set(
                    historyItemRef,
                    Object.assign(
                        {
                            name: item.name,
                            useCount: 0,
                        },
                        item.category && { category: item.category }
                    )
                );

                const itemRef = props.firestore
                    .collection('shoppinglists')
                    .doc(props.shoppinglistId)
                    .collection('items')
                    .doc(doc.id);
                t.set(
                    itemRef,
                    Object.assign(
                        {
                            name: item.name,
                            historyId: doc.id,
                        },
                        newMealPlan && { mealPlanId: newMealPlan.mealPlan.id },
                        item.category && { category: item.category }
                    )
                );

                if (newMealPlan) {
                    console.log('reallyAddItem with mealplan: ', item, newMealPlan);

                    const mealPlanRef = props.firestore
                        .collection('shoppinglists')
                        .doc(props.shoppinglistId)
                        .collection('meals')
                        .doc(newMealPlan.mealPlan.id);

                    t.update(mealPlanRef, {
                        itemIds: newMealPlan.mealPlan.itemIds.concat([doc.id]),
                    });
                }

                // return the new itemId
                return doc.id;
            });
        })
        .then((result) => {
            // TRANSACTION_SUCCESS action dispatched
            console.log('Transaction success!', result);
            //setTimeout(() => publish('itemAdded', result), 10000);
            setTimeout(publish, 250, 'itemAdded', result);
        })
        .catch((err) => {
            // TRANSACTION_FAILURE action dispatched
            console.log('Transaction failure:', err);
        });
};

const addNewItem = (props) => (item) => {
    return reallyAddNewItem(props, item);
};
const addNewItemToMealPlan = (props) => (item, newMealPlan) => {
    return reallyAddNewItem(props, item, newMealPlan);
};

const reallyAddItemFromHistory = (props, historyItem, newMealPlan) => {
    console.log('reallyAddItemFromHistory', historyItem, props);
    const itemRef = props.firestore.collection('shoppinglists').doc(props.shoppinglistId).collection('items').doc(); // TODO: remove this to fix item id issue
    const historyItemRef = props.firestore
        .collection('shoppinglists')
        .doc(props.shoppinglistId)
        .collection('historyitems')
        .doc(historyItem.id);

    props.firestore
        .runTransaction((t) => {
            return t.get(historyItemRef).then((doc) => {
                // This is the opposite of enumerating all the fields that should be on the item :)
                const newItemInfo = Object.assign(
                    { historyId: historyItem.id },
                    historyItem,
                    newMealPlan && { mealPlanId: newMealPlan.mealPlan.id }
                );
                newItemInfo.category = findCategory(newItemInfo.name);
                delete newItemInfo.id; // don't save an id field to the db ... let firebase do that

                t.set(itemRef, newItemInfo);
                t.update(historyItemRef, { useCount: props.firebase.firestore.FieldValue.increment(1) });

                if (newMealPlan) {
                    console.log('reallyAddItemFromHistory mealPlan: ', historyItem, newMealPlan);
                    const mealPlanRef = props.firestore
                        .collection('shoppinglists')
                        .doc(props.shoppinglistId)
                        .collection('meals')
                        .doc(newMealPlan.mealPlan.id);

                    console.error('What if this is a duplicate?!  Need the de-dup logic from the cloud function.');
                    t.update(mealPlanRef, {
                        itemIds: newMealPlan.mealPlan.itemIds.concat([historyItem.id]), // creates a new array object combining the parent and the params of concat
                    });
                }

                // return the id for the new item
                return itemRef.id;
            });
        })
        .then((result) => {
            // TRANSACTION_SUCCESS action dispatched
            console.log('Transaction success!', result);
            //publish('itemAdded', result);
            //setTimeout(() => publish('itemAdded', result), 10000);
            setTimeout(publish, 250, 'itemAdded', result);
        })
        .catch((err) => {
            // TRANSACTION_FAILURE action dispatched
            console.log('Transaction failure:', err);
        });
};

const addItemFromHistory = (props) => (historyItem) => {
    return reallyAddItemFromHistory(props, historyItem);
};

const addHistoryItemToMealPlan = (props) => (historyItem, newMealPlan) => {
    console.log('handlers.js:addHistoryItemToMealPlan');
    return reallyAddItemFromHistory(props, historyItem, newMealPlan);
};

// since we create the historyItem (and increment the useCount) when we create the item, we don't
// need to do anything except delete the item here
const deleteItem = (props) => (item) => {
    /* Not necessary anymore
    console.log('Deleting Item: ', item);
    if (item.mealPlanId) {
        return deleteItemFromMealPlanId(props, item, item.mealPlanId);
    }*/

    props.firestore.delete({
        collection: 'shoppinglists',
        doc: props.shoppinglistId,
        subcollections: [{ collection: 'items', doc: item.id }],
    });
};

// We no longer need to update both item and history.  Just update the item, the function trigger will take care of the history.
export const updateItem = (props) => (item) => {
    const newItemInfo = Object.assign(
        {
            name: item.name,
            historyId: item.historyId,
            safeway: item.hasOwnProperty('safeway') ? item.safeway : false,
        },
        item.imageId && { imageId: item.imageId },
        item.imageUrl && { imageUrl: item.imageUrl } // Object.assign won't pull "null" fields in
    );

    props.firestore
        .collection('shoppinglists')
        .doc(props.shoppinglistId)
        .collection('items')
        .doc(item.id)
        .update(newItemInfo)
        .then((result) => {
            // TRANSACTION_SUCCESS action dispatched
            //console.log('Transaction success - updateItem()!');
        })
        .catch((err) => {
            // TRANSACTION_FAILURE action dispatched
            console.error('updateItem() Transaction failure:', err, '\n', item);
        });
};

export const deleteItemImage = (props) => (item) => {
    const itemRef = props.firestore
        .collection('shoppinglists')
        .doc(props.shoppinglistId)
        .collection('items')
        .doc(item.id);
    const historyItemRef = props.firestore
        .collection('shoppinglists')
        .doc(props.shoppinglistId)
        .collection('historyitems')
        .doc(item.id); // they share the same id's

    props.firestore
        .runTransaction((t) => {
            return t.get(itemRef).then((doc) => {
                const newItemInfo = {
                    imageUrl: props.firestore.FieldValue.delete(),
                };

                t.update(itemRef, newItemInfo);
                t.update(historyItemRef, newItemInfo);
            });
        })
        .then((result) => {
            //console.log('Transaction success - deleteItemImage()!');
            // TODO:  the react-firebase library is updating the base item, but not the sorted list after this update.
            // TODO:  So the image looks like it is still there on the screen, but if you refresh it'll be gone.
            // TODO;  I'm assuming a bug on their end, but still work to do to either upgrade dependencies or somehow
            // TOOD:  force a flush(?!).
            // RESULT:  I stopped using "firebase.sorted" and just used "firebase.data" until the issue is resolved
            // https://github.com/prescottprue/redux-firestore/issues/243

            // Now delete the image ...
            if (item.imageId) {
                props.firebase.storage().ref('images').child(item.imageId).delete();
            }
        })
        .catch((err) => {
            console.error('deleteItemImage --> Transaction failure:', err);
        });
};

// Completely remove the item and it's history from our database.
export const deleteItemHistory = (props) => (item) => {
    props.firestore.delete({
        collection: 'shoppinglists',
        doc: props.shoppinglistId,
        subcollections: [{ collection: 'items', doc: item.id }],
    });
    props.firestore.delete({
        collection: 'shoppinglists',
        doc: props.shoppinglistId,
        subcollections: [{ collection: 'historyitems', doc: item.historyId }],
    });
    if (item.imageId) {
        props.firebase.storage().ref('images').child(item.imageId).delete();
    }
};

// **************************
// Meal Plans
//
//
// mealPlan = {
//      name: '',
//      itemIds: [],
//      historyId : ''  // added by cloud trigger
// }
//
// historyMealPlan = {
//      name: '',
//      historyItemIds: []      // references to historyitems.id, not items.id
// }
//

export const addNewMealPlan = (props) => (mealPlanIndex, name) => {
    console.log('addNewMealPlan', props.prevMealPlanRef);

    addNewMealPlanFromHistory(props)(mealPlanIndex, { name, historyItemIds: [] });

    /*
    props.firestore.add(
        {
            collection: 'shoppinglists',
            doc: props.shoppinglistId,
            subcollections: [{ collection: 'meals' }],
        },
        data
    );*/
};

// TODO:  addNewMealPlan will also need to update the meal plan ...
// TODO:  both of these methods will also need to remove old meals if they exist, which means passing in the previous meal ref
// TODO:  optional:  the .runTransaction ... return t.get(newRef) seems weird, google that one up

/*
export const updateMealList = (props) => (index, mealRef) => {
    props.firestore.update(
        {
            collection: 'shoppinglists',
            doc: props.shoppinglistId,
            subcollections: [{ collection: 'info', doc: 'meallist' }],
        },
        { [index]: mealRef || '' }
    ); // firebase doesn't store "null", so we use the empty string as a placeholder
};*/

// mealPlan may be null ...
export const addNewMealPlanFromHistory = (props) => (mealPlanIndex, historyMeal) => {
    const newMealRef = props.firestore.collection('shoppinglists').doc(props.shoppinglistId).collection('meals').doc(); // will create a new document with an id (another way to call add())

    console.log('addNewMealPlanFromHistory', props.prevMealPlanRef, historyMeal, mealPlanIndex, newMealRef.id);

    props.firestore
        .runTransaction((t) => {
            return t.get(newMealRef).then((doc) => {
                t.set(
                    newMealRef,
                    // wait ... we don't want the id field ...

                    // wait ... oh shit .. now we need to add all the items too?!  and ideally WITHIN this txn!  OMG!
                    Object.assign(
                        {},
                        //{ ...historyMeal },
                        historyMeal.id && { historyId: historyMeal.id }, // add it, if we know it
                        { name: historyMeal.name, itemIds: [] }
                    )
                );

                // this duplicates/replaces updateMealList above ...
                const mealPlanRef = props.firestore
                    .collection('shoppinglists')
                    .doc(props.shoppinglistId)
                    .collection('info')
                    .doc('meallist');

                t.update(
                    mealPlanRef,
                    { [mealPlanIndex]: doc.id || '' } // firebase doesn't store "null", so we use the empty string as a placeholder
                );

                // delete the prevMealPlanRef if there is one
                if (props.prevMealPlanRef) {
                    t.delete(
                        props.firestore
                            .collection('shoppinglists')
                            .doc(props.shoppinglistId)
                            .collection('meals')
                            .doc(props.prevMealPlanRef)
                    );
                }
            });
        })
        .then((result) => {
            // TRANSACTION_SUCCESS action dispatched
            console.log('Transaction success!');
        })
        .catch((err) => {
            // TRANSACTION_FAILURE action dispatched
            console.log('Transaction failure:', err);
        });
};

export const clearMealPlan = (props) => (mealPlanIndex, mealId) => {
    console.log('clearMealPlan: ', mealPlanIndex, mealId);

    const mealPlanRef = props.firestore
        .collection('shoppinglists')
        .doc(props.shoppinglistId)
        .collection('info')
        .doc('meallist');

    props.firestore
        .runTransaction((t) => {
            return t.get(mealPlanRef).then((doc) => {
                t.update(
                    mealPlanRef,
                    { [mealPlanIndex]: '' } // firebase doesn't store "null", so we use the empty string as a placeholder
                );

                if (mealId) {
                    t.delete(
                        props.firestore
                            .collection('shoppinglists')
                            .doc(props.shoppinglistId)
                            .collection('meals')
                            .doc(mealId)
                    );
                }
            });
        })
        .then((result) => {
            // TRANSACTION_SUCCESS action dispatched
            console.log('Transaction success!');
        })
        .catch((err) => {
            // TRANSACTION_FAILURE action dispatched
            console.log('Transaction failure:', err);
        });
};

export { addNewItem, addItemFromHistory, addNewItemToMealPlan, addHistoryItemToMealPlan, deleteItem };
