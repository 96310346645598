const config = {
    apiKey: 'AIzaSyACkW8Puh3UOIo0wWNuE7pQOJH-IQt1wbE',
    //authDomain: 'maijashoppinglist.firebaseapp.com',
    authDomain: 'shoppinglist.maija.xyz',
    databaseURL: 'https://maijashoppinglist.firebaseio.com',
    projectId: 'maijashoppinglist',
    storageBucket: 'maijashoppinglist.appspot.com',
    messagingSenderId: '276097255988'
};

export default config;