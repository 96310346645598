import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { firebaseConnect, firestoreConnect, isLoaded } from 'react-redux-firebase';

/**
 * This class sits between Login and the main App, and validates that the user has a shopping list when
 * they log in.  If they don't, it puts up a "Setting things up" message and creates a new shopping list,
 * then loads the app.
 */
class AppLoader extends Component {
    static propTypes = {
        isProfileLoaded: PropTypes.bool.isRequired,
        shoppinglistId: PropTypes.string, // could be null for new users
    };

    render() {
        const { isProfileLoaded, shoppinglistId } = this.props;
        //console.error('Render CreateShoppingList', isProfileLoaded, shoppinglistId);

        if (!isProfileLoaded) {
            return (
                <div className="loading">
                    <h3>Loading ...</h3>
                </div>
            );
        } else {
            if (!shoppinglistId) {
                // We are waiting for the cloud function to complete initialization ... 
                return (
                    <div className="loading">
                        <h3>Initializing ...</h3>
                    </div>
                );
            } else {
                // Otherwise, render the App!
                return <React.Fragment>{this.props.children}</React.Fragment>;
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        userId: state.firebase.auth.uid,
        isProfileLoaded: isLoaded(state.firebase.profile),
        shoppinglistId: state.firebase.profile.shoppinglist,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firebaseConnect(),
    firestoreConnect()
)(AppLoader);
