import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleButton from './components/GoogleButton';
import './login.css';

export class Login extends Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired
        }),

        clearFirestore: PropTypes.func.isRequired
    };

    handleClickLogin = () => {
        // This is a catch-22.
        // If you use 'popup', then react-redux-firebase will correctly create/update your user/profile, however, login then ceases to work on iOS devices.
        // If you use 'redirect', then you can log in on iOS devices, but your profile won't be created or updated.
        // Given that I've added a newUser auth cloud function, we can create the profile there, so we will stick with redirect.
        this.props.firebase.login({ provider: 'google', type: 'redirect' }).then(() => {  // 'popup' or "redirect"
            // I'm not sure this ever happens, because if they have never logged in before,
            // they will get redirected to the oauth login, and when they return this site
            // will reload into an authenticated context.
            console.log('User has logged in!');
        });
    };

    getMktgContent() {
        return (
            <div className="mktg">
                <div className="mktg_horiz">
                    <div className="mktg_left">
                        <div className="mktg_left_centered">
                            <div className="mktg_inputNew">
                                <div className="mktg_inputNew">
                                    <p className="itemName">Type something here</p>
                                    <p className="submit">&gt;</p>
                                </div>
                            </div>
                            <div className="mktg_itemWrapper">
                                <div className="itemImage"></div>
                                <p className="itemName">Museli</p>
                                <p className="clearItem">x</p>
                            </div>
                            <div className="mktg_itemWrapper">
                                <div className="itemImage"></div>
                                <p className="itemName">Eggs</p>
                                <p className="clearItem">x</p>
                            </div>
                            <div className="mktg_itemWrapper">
                                <div className="itemImage"></div>
                                <p className="itemName">Chicken</p>
                                <p className="clearItem">x</p>
                            </div>
                            <div className="mktg_itemWrapper">
                                <div className="itemImage"></div>
                                <p className="itemName">Bread</p>
                                <p className="clearItem">x</p>
                            </div>
                            <div className="mktg_itemWrapper">
                                <div className="itemImage"></div>
                                <p className="itemName">Milk</p>
                                <p className="clearItem">x</p>
                            </div>
                        </div>
                    </div>
                    <div className="mktg_right">
                        <div className="mktg_right_title">Family</div>
                        <div className="mktg_right_title">Shopping</div>
                        <div className="mktg_right_title">List</div>
                        <div className="mktg_right_subtext">We Support Google Home!</div>
                        <div className="login">
                            <GoogleButton
                                onClick={this.handleClickLogin /*async () => {
                                    await this.props.firebase.login({ provider: 'google', type: 'redirect' });
                                }*/}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { isLoading, isLoggedIn } = this.props;

        if (isLoading) {
            return (
                <div className="loading">
                    <h3>Loading ...</h3>
                </div>
            );
        }
        if (!isLoggedIn) {
            return this.getMktgContent();
        }

        // Otherwise, render the App!
        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

export default Login;
