import { EDIT_ITEM_START, EDIT_ITEM_COMPLETED } from './actions';

const initialState = {
    editing: false,
    itemId: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case EDIT_ITEM_START:
            return {
                editing: true,
                itemId: action.itemId
            };

        case EDIT_ITEM_COMPLETED:
            return {
                editing: false,
                itemId: null
            };

        default:
            return state;
    }
}
