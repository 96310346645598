import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import EditItemContainer from './EditItemContainer';
import ListItem from './components/ListItem';
import { findCategory } from './CategorySorter';
import NewItem from './components/NewItem';
import { subscribe, unsubscribe } from './util/pubsub';
import './App.css';
import './transition.css';

class ShoppingList extends Component {
    static propTypes = {

        // Lists of data
        isListLoaded: PropTypes.bool.isRequired,
        items: PropTypes.array.isRequired,
        historyitems: PropTypes.array.isRequired,

        // Redux actions
        editItemStart: PropTypes.func.isRequired,

        // Item functions
        addNewItem: PropTypes.func.isRequired,
        addItemFromHistory: PropTypes.func.isRequired,
        deleteItem: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.addNewItem = this.addNewItem.bind(this);
        this.addItemFromHistory = this.addItemFromHistory.bind(this);
        this.deleteItem = this.deleteItem.bind(this);

        this.myRefs = {};
    }

    componentDidMount() {
        subscribe('itemAdded', this.itemAddedCallback);
    }
    componentWillUnmount() {
        unsubscribe('itemAdded', this.itemAddedCallback);
    }

    itemAddedCallback = (itemId) => {
        console.log('itemAddedCallback', itemId, this.myRefs[itemId]);

        // just in case the callback gets called before the item initially renders
        if (this.myRefs[itemId] && this.myRefs[itemId].current) {
            this.myRefs[itemId].current.scrollIntoView({
                behavior: 'smooth',
                block: 'center', //'start',
            });
        }
    };

    renderListItems() {
        const { items } = this.props;

        let result = [];
        for (var i = 0; i < items.length; i++) {
            const item = items[i];
            if (!this.myRefs[item.id]) {
                this.myRefs[item.id] = React.createRef();
            }

            //result[i] = <div key={item.id} ref={this.myRefs[item.id]}>{item.name}</div>;

            result[i] = (
                <ListItem
                    key={item.id}
                    myRef={this.myRefs[item.id]}
                    item={item}
                    handleItemEdit={this.editItem}
                    handleDeleteItem={this.deleteItem}
                />
            );
        }
        return result;
    }

    addNewItem(itemName) {
        const { addNewItem } = this.props;

        // TODO:  search items to see if we already have one like this
        // TODO:  do we need to search our history of items to see if we have one, or do we assume the UI basically did that?

        // Add the new item to firebase
        // TODO: what if it was a history item?  do we care?
        addNewItem({
            name: itemName,
            category: findCategory(itemName),
        });
        // TODO:  add history item here
        // Note:  add the history item first, so that we get the ID?!?!?  FUCK!
    }
    addItemFromHistory(historyitem) {
        const { addItemFromHistory } = this.props;
        addItemFromHistory(historyitem);
    }

    deleteItem(item) {
        // Delete the item in firebase
        const { deleteItem } = this.props;
        deleteItem(item);
    }

    editItem = (item) => {
        const { editItemStart } = this.props;
        editItemStart(item.id);
    };

    render() {
        const { isListLoaded, historyitems } = this.props;
        const { items } = this.props;
        //console.error('My Shopping List Items', JSON.stringify(items, null, 2));
        //console.error('My History List Items', JSON.stringify(historyitems, null, 2));

        // if the initial list load isn't complete, don't render yet (screws up intial animations on the render)
        if (!isListLoaded) {
            return <div className="loading"><h3>Loading ...</h3></div>;
        }

        return (
            <React.Fragment>
                <EditItemContainer />
                <div className="inputWrapper">
                    <NewItem
                        handleAddItem={this.addNewItem}
                        handleAddItemFromHistory={this.addItemFromHistory}
                        history={historyitems}
                    />
                </div>
                <div className="space" />

                <TransitionGroup
                    className="foo"
                    /*appear={false}   appear does not seem to do anything, but enter and exit do */
                    /*enter={true}*/
                    exit={false}
                >
                    {this.renderListItems().map((element, index) => (
                        <CSSTransition
                            /*in={true}*/ key={items[index].id}
                            timeout={2000}
                            classNames="itemtransition" /*appear={false}*/
                        >
                            {element}
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </React.Fragment>
        );
    }
}

export default ShoppingList;
