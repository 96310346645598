import { createStore, compose, applyMiddleware } from 'redux';
//import { reactReduxFirebase } from 'react-redux-firebase';
//import { reduxFirestore } from 'redux-firestore';
import { createFirestoreInstance } from 'redux-firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
//import 'firebase/functions';
import FirebaseConfig from './FirebaseConfig.js';
import { initialState, rootReducer } from './reducers';
import { logger } from './middleware';

// react-redux-firebase config
const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

// Initialize firebase instance
firebase.initializeApp(FirebaseConfig);
//firebase.firestore().settings({ timestampsInSnapshots: true });

// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore

// For debugging locally ...
//firebase.functions() // <- needed if using httpsCallable
//firebase.functions().useFunctionsEmulator('http://localhost:5001');

// make a reference to our firebase storage
//export const storage = firebase.storage().ref();          
// Not clear that this is used, and there is something wrong with the import above.
// See FileUploader.jsx for something that works.

//const enhancers = [reduxFirestore(firebase), reactReduxFirebase(firebase, rrfConfig)];
const enhancers = [];
//const reduxDevToolsExtension = window.devToolsExtension;
const reduxDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
if (process.env.NODE_ENV === 'development' && typeof reduxDevToolsExtension === 'function') {
    enhancers.push(reduxDevToolsExtension());
}

// Put the dev tool extension last, as it wraps actions in a "PERFORM_ACTION" action wrapper.
// https://stackoverflow.com/questions/49436872/what-is-perform-action-action-type-inside-redux
const composedEnhancers = compose(
    applyMiddleware(logger),
    ...enhancers
);

const store = createStore(rootReducer, initialState, composedEnhancers);

export default store;

export const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
};
