import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
//import * as Constants from '../constants';

class ListItem extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        myRef: PropTypes.object.isRequired,
        handleDeleteItem: PropTypes.func.isRequired,
        handleItemEdit: PropTypes.func, // will be null in MealPlanningMode
    };

    constructor(props) {
        super(props);
        this.deleteMe = this.deleteMe.bind(this);

        this.state = {
            editItem: null,
            visible: true
        };
    }

    getImage = () => {
        const { item } = this.props;

        if (!item.imageUrl) {
            return;
        }

        return <img className="itemImage" src={item.imageUrl} alt={''} />;
    };

    deleteMe() {
        const { item, handleDeleteItem } = this.props;
        handleDeleteItem(item);
        this.setState({visible: false});
    }

    editMe = () => {
        const { item, handleItemEdit } = this.props;

        handleItemEdit(item);
    };

    render() {
        const { item, myRef } = this.props;
        const { editItem, visible } = this.state;

        return (
            /*<CSSTransition
                in={visible}
                key={item.id}
                classNames="example"
                timeout={{ appear: 0, enter: 3000, exit: 0 }}
                appear={true}
                enter={true}
                exit={false}
                unmountOnExit
            >*/
                <div className="itemWrapper" ref={myRef}>
                    <div className="itemImage" onClick={this.editMe}>
                        {this.getImage()}
                    </div>
                    <p className="itemName">{item.name}</p>
                    {item.safeway ? <p className="safeway">S</p> : null}
                    <p className="clearItem" onClick={this.deleteMe}>
                        x
                    </p>
                    {editItem}
                </div>
           /* </CSSTransition>*/
        );
    }
}

export default ListItem;
