import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Suggestion extends Component {
    static propTypes = {
        historyItem: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.onClickSubmit = this.onClickSubmit.bind(this);
    }

    onClickSubmit() {
        const { handleAddItem } = this.props;
        handleAddItem(this.props.nameId);
    }

    render() {
        const { name, historyItem } = this.props;

        let image = '';
        if (historyItem.imageUrl) {
            image = <img className="itemImage" src={historyItem.imageUrl} alt={''} />;
        }

        return (
            <div className="itemWrapper suggestion" onClick={this.onClickSubmit}>
                <div className="itemImage">{image}</div>

                <p className="suggestionName">{name}</p>
            </div>
        );
    }
}

class NewItem extends Component {
    static propTypes = {
        history: PropTypes.array.isRequired,
        handleAddItem: PropTypes.func.isRequired,
        handleAddItemFromHistory: PropTypes.func.isRequired,
        autoFocus: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = { value: '' };

        this.handleChange = this.handleChange.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);
        this.addFromSuggestion = this.addFromSuggestion.bind(this);

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const { autoFocus } = this.props;

        if (autoFocus) {
            this.inputRef.current.focus();
        }
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    onClickSubmit() {
        const { handleAddItem } = this.props;
        handleAddItem(this.state.value);
        this.setState({ value: '' });
    }

    addFromSuggestion(i) {
        const { history } = this.props;
        //console.log(history + '....' + i + '....' + history[i]);

        const { handleAddItemFromHistory } = this.props;
        handleAddItemFromHistory(history[i]);
        this.setState({ value: '' });
    }

    renderSuggestions() {
        const { history } = this.props;

        if (this.state.value !== '' && this.state.value !== ' ') {
            let result = [];
            let j = 0;
            const value = this.state.value.toLowerCase();

            for (var i = 0; i < history.length; i++) {
                let name = history[i].name.toLowerCase();

                if (name.indexOf(value) !== -1) {
                    result[j] = (
                        <Suggestion
                            key={i}
                            nameId={i}
                            name={history[i].name}
                            historyItem={history[i]}
                            handleAddItem={this.addFromSuggestion}
                        />
                    );
                    j++;
                }
            }
            return result;
        }
    }
    // <div className="inputWrapper">
    render() {
        return (
            <React.Fragment>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <div className="inputNewWrapper">
                        <input
                            className="inputNew"
                            placeholder="Type something here "
                            type="text"
                            onChange={this.handleChange}
                            value={this.state.value}
                            ref={this.inputRef}
                        />
                        <input className="submit" type="submit" value="&gt;" onClick={this.onClickSubmit} />
                    </div>
                </form>
                <div className="suggestionsWrap">{this.renderSuggestions()}</div>
            </React.Fragment>
        );
    }
}

export default NewItem;
