import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { deleteItem } from '../redux/firebasehandlers';
import { editMealPlanStart } from '../redux/actions';
import MealPlanningMode from './MealPlanningMode';

// use a function generator, not a predefined constant data which can get corrupted
function getMealPlanTemplate() {
    return [
        {
            id: 0,
            title: 'Monday',
            mealPlan: null,
            mealPlanItems: [],
        },
        {
            id: 1,
            title: 'Tuesday',
            mealPlan: null,
            mealPlanItems: [],
        },
        {
            id: 2,
            title: 'Wednesday',
            mealPlan: null,
            mealPlanItems: [],
        },
        {
            id: 3,
            title: 'Thursday',
            mealPlan: null,
            mealPlanItems: [],
        },
        {
            id: 4,
            title: 'Friday',
            mealPlan: null,
            mealPlanItems: [],
        },
        {
            id: 5,
            title: 'Saturday',
            mealPlan: null,
            mealPlanItems: [],
        },
        {
            id: 6,
            title: 'Sunday',
            mealPlan: null,
            mealPlanItems: [],
        },
    ];
}

const mapStateToProps = (state) => {
    let data = getMealPlanTemplate();
    if (state.firestore.data.shoppinglistinfo) {
        // convert an object with keys 0..6 into an array indexed 0..6
        const mealInfo = state.firestore.data.shoppinglistinfo;
        const meals = state.firestore.data.meals || {};

        data.forEach((meal, i) => {
            if (mealInfo[i] && meals[mealInfo[i]]) {
                meal.mealPlan = meals[mealInfo[i]];
                meal.mealPlan.id = mealInfo[i]; // the firestore.data doesn't have id's, so we add it in here
            }
        });
    }

    const mealPlanItems = {};
    // construct a meal plan list from our shopping list items
    state.firestore.ordered.currentItemList.forEach((item) => {
        if (item.mealPlanId) {
            if (!mealPlanItems[item.mealPlanId]) {
                mealPlanItems[item.mealPlanId] = [];
            }
            mealPlanItems[item.mealPlanId].push(item);
        }
    });
    // not efficient, but the numbers are small :)
    Object.keys(mealPlanItems).forEach((mealPlanId) => {
        data.forEach((mealInfo) => {
            if (mealInfo.mealPlan && mealInfo.mealPlan.id === mealPlanId) {
                mealInfo.mealPlanItems = mealPlanItems[mealPlanId];
            }
        });
    });

    console.log('MealPlanContainer: ', data);

    return {
        myMealPlans: data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editMealPlanStart: (mealPlanId) => dispatch(editMealPlanStart(mealPlanId)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect(),
    withHandlers({
        deleteItem
    })
)(MealPlanningMode);
