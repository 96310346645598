import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import shoppinglistReducer from './shoppinglist';
import edititemReducer from './edititem';
import editmealplanReducer from './editmealplan';

export const initialState = {};

export const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    shoppinglist: shoppinglistReducer,
    edititem: edititemReducer,
    editmealplan: editmealplanReducer
});
