export const EDIT_ITEM_START = 'EDIT_ITEM_START';
export const EDIT_ITEM_COMPLETED = 'EDIT_ITEM_COMPLETED';

export function editItemStart(itemId) {
    return {
        type: EDIT_ITEM_START,
        itemId: itemId
    };
};

export function editItemCompleted() {
    return {
        type: EDIT_ITEM_COMPLETED
    };
};


export const EDIT_MEALPLAN_START = 'EDIT_MEALPLAN_START';
export const EDIT_MEALPLAN_COMPLETED = 'EDIT_MEALPLAN_COMPLETED';

export function editMealPlanStart(mealPlanId) {
    return {
        type: EDIT_MEALPLAN_START,
        mealPlanId
    };
};

export function editMealPlanCompleted() {
    return {
        type: EDIT_MEALPLAN_COMPLETED
    };
};
