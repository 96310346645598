import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import Login from './Login';

const mapStateToProps = state => {
    return {
        isLoading: !isLoaded(state.firebase.auth),
        isLoggedIn: !isEmpty(state.firebase.auth)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearFirestore: () => dispatch({ type: '@@reduxFirestore/CLEAR_DATA' })
    };
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firebaseConnect()
)(Login);