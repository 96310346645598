import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { editMealPlanCompleted } from '../redux/actions';
import { addNewMealPlan, addNewMealPlanFromHistory, clearMealPlan } from '../redux/firebasehandlers';
import EditMealPlan from './EditMealPlan';

const mapStateToProps = state => {

    return {
        open: state.editmealplan.editing,
        mealPlanId: state.editmealplan.mealPlanId,
        historyMeals: state.firestore.ordered.historymeals || [],

        // We add this to the state so that it is available to the handler functions below
        shoppinglistId: state.firebase.profile.shoppinglist,    // to enable the handler functions below
        prevMealPlanRef: state.editmealplan.mealPlanId ? state.firestore.data.shoppinglistinfo[state.editmealplan.mealPlanId] : null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        //editMealPlanStart: mealPlanId => dispatch(editMealPlanStart(mealPlanId)),
        closeDrawer: () => dispatch(editMealPlanCompleted())
    };
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firebaseConnect(),
    firestoreConnect(),
    withHandlers({
        addNewMealPlan,
        addNewMealPlanFromHistory,
        clearMealPlan
    })
)(EditMealPlan);
