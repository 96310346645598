import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SelectMealPlanDrawer extends Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        closeDrawer: PropTypes.func.isRequired,

        // data
        mealPlanId: PropTypes.any,
        historyMealPlans: PropTypes.array,

        // Firebase handlers
        addNewMealPlan: PropTypes.func.isRequired,
        addNewMealPlanFromHistory: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            mealName: '',
        };
    }
    handleChange = (event) => {
        this.setState({ mealName: event.target.value, formDirty: true });
    };
    addIt = () => {
        const { mealName } = this.state;
        const { addNewMealPlan, mealPlanId, closeDrawer } = this.props;

        console.log('Add new meal plan named: ', mealName);

        if (!mealName || mealName.length === 0) {
            return;
        }

        addNewMealPlan(mealPlanId, mealName);
        closeDrawer();
    };
    clearMeal = () => {
        const { mealPlanId, prevMealPlanRef, clearMealPlan, closeDrawer } = this.props;

        clearMealPlan(mealPlanId, prevMealPlanRef);
        closeDrawer();
    };

    handleClose = () => {
        const { closeDrawer } = this.props;
        closeDrawer();
    };

    handleHistoryMealClick = (id) => {
        const { mealPlanId, historyMeals, addNewMealPlanFromHistory, closeDrawer } = this.props;

        console.log('You clicked on ', id, historyMeals[id]);

        addNewMealPlanFromHistory(mealPlanId, historyMeals[id]);
        closeDrawer();
    };

    getHistoryMeals = () => {
        const { historyMeals } = this.props;

        console.log('historyMeals HERE', historyMeals);

        const sorted = historyMeals.sort((firstEl, secondEl) => ('' + firstEl.name).localeCompare(secondEl.name));
        console.log(sorted);

        // sort list before displaying
        return historyMeals
            .sort((firstEl, secondEl) => ('' + firstEl.name).localeCompare(secondEl.name))
            .map((histMealInfo, index) => {
                return (
                    <li key={index} onClick={() => this.handleHistoryMealClick(index)}>
                        {histMealInfo.name}
                    </li>
                );
            });
    };

    getContent = () => {
        const { mealName } = this.state;

        return (
            <div key="something" className="drawer">
                <div className="drawer_content">
                    <div className="drawer_close" onClick={this.handleClose}>
                        x
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <div className="drawer_newitem_wrapper">
                            New Meal Name:
                            <input
                                //className="edititem_input"
                                type="text"
                                value={mealName}
                                onChange={this.handleChange}
                            />
                            <input className="submit" type="submit" value="&gt;" onClick={this.addIt} />
                            <button onClick={this.clearMeal}>Clear</button>
                        </div>
                    </form>
                    <div>Or select from a previous meal:</div>
                    <ul className="emp_meallist">{this.getHistoryMeals()}</ul>
                </div>
            </div>
        );
    };

    render() {
        const { open } = this.props;
        return open ? this.getContent() : null;
    }
}

export default SelectMealPlanDrawer;
