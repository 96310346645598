const subscribers = {};

export const publish = (eventName, data) => {
    if (!Array.isArray(subscribers[eventName])) {
        return;
    }
    subscribers[eventName].forEach((callback) => {
        callback(data);
    });
}

export const subscribe = (eventName, callback) => {
    if (!Array.isArray(subscribers[eventName])) {
        subscribers[eventName] = [];
    }
    subscribers[eventName].push(callback);
}

export const unsubscribe = (eventName, callback) => {
    subscribers[eventName] = subscribers[eventName].filter((cb) => {
      // Does not include the callback in the new array
      if (cb === callback) {
        return false
      }
      return true
    })
}