import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ShoppingListMode from './ShoppingListMode';
import MealPlanningModeContainer from './mealplanning/MealPlanningModeContainer';
import * as Constants from './constants';
import './App.css';


class App extends Component {
    static propTypes = {
        // Lists of data
        items: PropTypes.array.isRequired,
        historyitems: PropTypes.array.isRequired,

        // Redux actions
        editItemStart: PropTypes.func.isRequired,

        // Item functions
        addNewItem: PropTypes.func.isRequired,
        addItemFromHistory: PropTypes.func.isRequired,
        deleteItem: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            listmode: Constants.SHOPPING_MODE
        };
    }

    toggleMode = () => {
        const { listmode } = this.state;

        const newmode = listmode === Constants.PLANNING_MODE ? Constants.SHOPPING_MODE : Constants.PLANNING_MODE;
        this.setState({
            listmode: newmode
        });
    }

    render() {
        const { listmode } = this.state;
        //const { historyitems } = this.props;

        return (
            <div className="App">
                { listmode === Constants.SHOPPING_MODE ? <ShoppingListMode {...this.props}/> : <MealPlanningModeContainer {...this.props}/> }
                <div className="smallspace" />
                <div className="itemImage moreButton" onClick={this.toggleMode}/>
            </div>
        );
    }
}

export default App;
